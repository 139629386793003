import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { BarcodeScanner } from "react-barcode-scanner";
import "react-barcode-scanner/polyfill";

function CapturedQr(qr) {
  if (qr.length === 0) return;
  const item = qr[0];
  window.location.href = `http://192.168.1.90:8080/#/connection/${item.rawValue}${window.location.search}`;
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BarcodeScanner onCapture={CapturedQr}></BarcodeScanner>
  </React.StrictMode>
);
